import React from "react"
import styled from "styled-components"

import iphone from '../images/iphone.png';


const Container = styled.div`
  position : relative;
  width    : 28.9rem;
  height   : 57rem;
`;

const Screenshot = styled.div`
  position : absolute;
  width: 24.5rem;
  height: 53rem;
  top: 2rem;
  left: 2rem;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: white;
`;

const Frame = styled.img`
  position : absolute;
  width    : 28.9rem;
  height   : 57rem;
`;

export default ({src, ...props}) => (
  <Container {...props}>
    <Screenshot style={{backgroundImage : `url('${src}')`}} />
    <Frame src={iphone} />
  </Container>
);
