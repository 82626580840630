import React, {useEffect, useRef} from "react"
import styled from "styled-components"

import iphone from '../images/iphone.png';


const Container = styled.div`
  position : relative;
  width    : 28.9rem;
  height   : 57rem;
  flex-basis : 28.9rem;
  flex-shrink : 0;
`;

const Video = styled.video`
  position : absolute;
  width: 24.6rem;
  height: 53rem;
  top: 2rem;
  left: 2rem;
`;

const Frame = styled.img`
  position : absolute;
  width    : 28.9rem;
  height   : 57rem;
`;

export default ({src, ...props}) => {
  const videoRef = useRef(null)

  useEffect(() => {
    const { current: videoElement } = videoRef
    videoElement.setAttribute('muted', '')
  }, [])

  const toggle = () => videoRef.current.paused ? videoRef.current.play() : () => {};

  return (
    <Container {...props} onClick={toggle}>
      <Video ref={videoRef} autoPlay muted playsInline loop>
        <source src={src} type="video/mp4" />
      </Video>
      <Frame src={iphone} />
    </Container>
  );
}
