import React, {useEffect, useRef} from "react"
import styled from "styled-components"

import mac from '../images/mac.png';


const Container = styled.div`
  position : relative;
  width    : 92.3rem;
  height   : 54.4rem;
  flex-basis : 92.3rem;
  flex-shrink : 0;
`;

const Video = styled.video`
  position : absolute;
  width: 70.8rem;
  height: 44.2rem;
  top: 4rem;
  left: 10.7rem;
`;

const Frame = styled.img`
  position : absolute;
  width    : 92.3rem;
  height   : 54.4rem;
`;

export default ({src, ...props}) => {
  const videoRef = useRef(null)

  useEffect(() => {
    const { current: videoElement } = videoRef
    videoElement.setAttribute('muted', '')
  }, [])

  const toggle = () => videoRef.current.paused ? videoRef.current.play() : () => {};

  return (
    <Container {...props} onClick={toggle}>
      <Video ref={videoRef} autoPlay muted playsInline loop>
        <source src={src} type="video/mp4" />
      </Video>
      <Frame src={mac} />
    </Container>
  );
}
