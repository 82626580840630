

const references = {
  woaw : {
    name : 'WoaW (Write on a Wall)',
    shortName : 'WoaW',
    description : 'WoaW est un mur virtuel dans lequel on peut créer des graffitis photoréalistes avec des effets de lumières en 3D.',
    hashtags : ['OpenGL', '3D'],
    screenshots : {
      type  : 'mobile-image-image',
      imageSmall : require('./references/woaw-logo.png'),
      imageBig   : require('./references/woaw-screenshot.jpg')
    },
    categories : ['mobile']
  },
  appero : {
    name : 'Appero',
    shortName : 'Appero',
    description : 'Commandez votre consommation directement depuis votre téléphone !',
    hashtags : ['MVP', 'ReactNative'],
    screenshots : {
      type  : 'mobile-image-video',
      image : require('./references/appero-logo-dark-blue.png'),
      video : require('./references/appero-export-min.mp4')
    },
    categories : ['mobile']
  },
  chillter : {
    name : 'Chillter',
    shortName : 'Chillter',
    description : `Un réseau social basé sur l'organisation d'évènements. Chat, invitations, photos... tout y est!`,
    hashtags : ['MVP', 'Chat', 'ReactNative'],
    screenshots : {
      type  : 'mobile-image-video',
      image : require('./references/chillter-logo.png'),
      video : require('./references/chillter-export.mp4')
    },
    categories : ['mobile']
  },
  dresswing : {
    name : 'Dresswing',
    shortName : 'Dresswing',
    description : 'Location de vêtements entre particuliers. Reprise et évolution d\'une application existante en tant que CTO externalisé.',
    hashtags : ['CTO externalisé', 'ReactNative'],
    screenshots : {
      type  : 'mobile-image-video',
      image : require('./references/dresswing-logo.png'),
      video : require('./references/dresswing-export.mp4')
    },
    categories : ['mobile', 'cto']
  },
  planifeye : {
    name : 'Planifeye',
    shortName : 'Planifeye',
    description : 'Un outil de management nouvelle génération. Création d\'une plateforme SaaS from scratch.',
    hashtags : ['SaaS', 'CTO externalisé'],
    screenshots : {
      type  : 'laptop-video',
      video : require('./references/planifeye-export.mp4')
    },
    categories : ['web']
  },
  hkind : {
    name : 'Hkind',
    shortName : 'Hkind',
    description : 'Partage des initiatives de santé. Reprise et évolution d\'une application existante en tant que CTO externalisé.',
    hashtags : ['Santé', 'CTO', 'PWA'],
    screenshots : {
      type : 'mobile-image-video',
      image : require('./references/hkind-logo.png'),
      video : require('./references/hkind-export.mp4')
    },
    categories : ['mobile', 'web', 'cto']
  },
  caf91 : {
    name : 'Caf de l\'Essonne',
    shortName : 'Caf 91',
    description : 'Une petite application aux couleurs de la Caf de l\'Essonne pour rapprocher les utilisateurs avec la Caisse.',
    hashtags : ['ReactNative'],
    screenshots : {
      type : 'mobile-image-video',
      image : require('./references/caf91-logo.png'),
      video : require('./references/caf91-export.mp4')
    },
    categories : ['mobile']
  },
  meetworking : {
    name : 'Meetworking',
    shortName : 'Meetworking',
    description : `Plateforme pour la création d'évènements entre collègues. Reprise de l'existant et mise à jour.`,
    hashtags : ['CTO', 'Synfony'],
    screenshots : {
      type : 'laptop-image',
      image : require('./references/meetworking.jpg'),
    },
    categories : ['web']
  },
  bluedotapp : {
    name : 'MoovDot App',
    shortName : 'Moovdot',
    description : `Une application tablette et un algorithme de ML pour simplifier l'installation de la solution Moovdot de Wx Solutions`,
    hashtags : ['ML', 'Algorithmes'],
    screenshots : {
      type : 'tablet-video',
      video : require('./references/video-test-3.mp4'),
    },
    categories : ['web', 'mobile']
  },
  dgfip : {
    name : 'Data-Perf',
    shortName : 'DGFiP',
    description : 'Une application de datavisualisation conçue sur mesure pour la DGFiP',
    hashtags : ['Dataviz', 'Institutionnel'],
    screenshots : {
      type : 'laptop-video',
      video : require('./references/video-globale-dgfip.mp4'),
    },
    categories : ['web', 'cto']
  },
  lightinderm : {
    name : 'Bootloader Lightinderm',
    shortName : 'LID',
    description : `Un outil facile à utiliser pour mettre à jour les devices Lightinderm`,
    hashtags : [],
    screenshots : {
      type : 'laptop-video',
      video : require('./references/video-lightindream.mp4'),
    },
    categories : ['web', 'cto']
  },
  ore : {
    name : 'Agence ORE',
    shortName : 'ORE',
    description : `Dataviz interactive sur les énergies renouvelables`,
    hashtags : ['Dataviz', 'Cartographie'],
    screenshots : {
      type : 'laptop-video',
      video : require('./references/video-ore-agence.mp4'),
    },
    categories : ['web', 'cto']
  },
  agami : {
    name : 'Agami',
    shortName : 'Agami',
    description : `Un outil sur mesure pour la création de propositions commerciales`,
    hashtags : ['PDF', 'React'],
    screenshots : {
      type : 'laptop-video',
      video : require('./references/agami.mp4'),
    },
    categories : ['web', 'cto']
  },
  conflit : {
    name : 'Une histoire du conflit politique',
    shortName : 'Conflit politique',
    description : `Une application interactive pour naviguer en temps réel dans les données à la base du livre "Une histoire du conflit politique"`,
    hashtags : ['Dataviz', 'Gestion des données', 'Cartographie'],
    screenshots : {
      type : 'laptop-video',
      video : require('./references/conflit.mp4'),
    },
    categories : ['web', 'cto']
  },
  oir : {
    name : 'Plateforme de données territoriales - OIR',
    shortName : 'OIR',
    description : `Un outil de datavisualisation pour les données de l'Observatoire de l'Intérim et du Recrutementq`,
    hashtags : ['Dataviz', 'Cartographie'],
    screenshots : {
      type : 'laptop-video',
      video : require('./references/oir.mp4'),
    },
    categories : ['web', 'cto']
  },
  marbl : {
    name : 'Marbl',
    shortName : 'Marbl',
    description : `Outil de création de tunnels de vente pour le secteur de la finance`,
    hashtags : ['Architecture logicielle', 'Finance'],
    screenshots : {
      type : 'laptop-video',
      video : require('./references/marbl.mp4'),
    },
    categories : ['web', 'cto']
  },
  monuma : {
    name : 'Monuma',
    shortName : 'Monuma',
    description : `Interface d'administration pour le service d’huissier blockchain Monuma`,
    hashtags : ['Dashboard', 'Blockchain'],
    screenshots : {
      type : 'laptop-video',
      video : require('./references/monuma.mp4'),
    },
    categories : ['web', 'cto']
  }

};

export default {
  home : [
    // references.woaw,
    references.conflit,
    // references.agami,
    references.dgfip,
    references.oir,
    references.bluedotapp,
    // references.caf91,
    references.planifeye,
    // references.dresswing,
  ],
  references : [
    references.conflit,
    references.dgfip,
    references.oir,
    references.marbl,
    references.bluedotapp,
    references.monuma,
    references.caf91,
    references.planifeye,
    references.lightinderm,
    references.appero,
    references.ore,
    references.woaw,
    references.dresswing,
    references.chillter,
    references.hkind,
    references.meetworking,
    // references.agami,
  ]
};

export const categories = [
  {key : 'cto',    name : 'CTO'},
  {key : 'mobile', name : 'Mobile'},
  {key : 'web',    name : 'Web'},
];
